<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.cnsuo" placeholder="方案名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.cnsuo" placeholder="功耗类型"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.cnsuo" placeholder="通讯协议"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.cnsuo" placeholder="品类code"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addPlan">增加</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column label="方案名称">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="通讯协议">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图标">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="功耗类型">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="品类名称">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="品类code">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="info" size="small" @click="planInfo(scope.row)">详情</el-button>
          <el-button type="primary" size="small" @click="editPlan(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deletePlan(scope.row)">删除</el-button>
          <el-button type="info" size="small" @click="distributePlan(scope.row)">分配功能</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增修改 -->
    <el-dialog title="新增/编辑方案" v-model="dialogFormVisible" width="600px">
      <el-form :model="form">
        <el-form-item label="方案名称" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="品类code" label-width="100px">
          <el-input v-model="form.name" type="text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="通讯协议" label-width="100px">
          <el-input v-model="form.name" type="text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="方案说明" label-width="100px">
          <el-input v-model="form.name" type="text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="功耗类型" label-width="100px">
          <el-input v-model="form.name" type="text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标上传" label-width="100px">
          <el-input v-model="form.name" type="text" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="addconfirm">新增</el-button>
          <el-button type="primary" v-if="edit" @click="editconfirm">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        cnsuo: '',
        cocn: '',
      },
      list: [
        {describe: '一个毛栗子'}
      ],
      dialogFormVisible: false,
      form: {

      },
      edit: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    addPlan () {
      this.edit = false
      this.dialogFormVisible = true
    },
    editPlan(item) {
      if (item) {
        this.edit = true
      } else {
        this.edit = false
      }
      this.dialogFormVisible = true
    },
    deletePlan(item) {
      this.$confirm('是否删除该方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    planInfo (){

    },
    distributePlan (){
        this.$router.push('/iotmanage/plan/distributefun')
    },
    addconfirm () {

    },
    editconfirm () {

    },
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
